/**
 * Possible error codes.
 */
export enum ErrorCode
{
  Unknown = 'Unknown',

  // Storage
  StorageError = 'StorageError',

  // Network
  NoInternet = 'NoInternet',

  // Model Validation Error
  BadRequest = 'BadRequest',

  // Unauthorised
  Unauthorised = 'Unauthorised',

  // Forbidden error.
  Forbidden = 'Forbidden',

  // Validation Error
  NotFound = 'NotFound',

  // Unknown user error
  UnknownUser = 'UnknownUser',

  // For any other 4xx or 5xx error.
  RequestError = 'RequestError',

  // Internal server error.
  ServerError = 'ServerError',
}

/**
  * Error class used to pass errors around the system
  */
export class ThirtyOneError extends Error
{
  constructor(public errorCode: ErrorCode, public message: string)
  {
    super(message);
  }
}
