import { BaseSyntheticEvent } from 'react';
import Logger from '../Logger/Logger';
import Time from '../Models/Time';
import Timespan from '../Models/Timespan';
import DateRange from '../Models/DateRange';

const logger = Logger.Create('Utils');

// Declare window interfaces so it doesn't fail to build.
declare global
{
  interface Window
  {
    opera: any;
  }
}

/**
 * The Validated Url Result.
 */
export interface ValidatedUrlResult
{
  /**
   * Valid URL flag.
   */
  isValid: boolean;

  /**
   * The validated url.
   */
  url: string;

  /**
   * The token.
   */
  token: string;
}

/**
 * Utility service.
 */
export default class Utils
{
  /**
   * Disables the context menu when the user taps and hold on the screen.
   * @param event The touch event.
   */
  public static disableContextMenu(event: BaseSyntheticEvent<MouseEvent,
    EventTarget & HTMLDivElement, EventTarget>): void
  {
    // Ensures that any touch events does not go past
    // this rendered component.
    event.preventDefault();
  }

  /**
   * Adds a comma to every 3rd position in a number.
   *
   * e.g. 200000 => 200,000;
   * @param input The input number.
   */
  public static commafyEveryThirdNumber(input?: number): string
  {
    if (input === undefined)
    {
      return '';
    }

    return Math.round(input).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  }

  /**
   * Validates if a given string is a JWT or not.
   * @param input The input string.
   */
  public static isJwt(input: string): boolean
  {
    const pattern = /(\w)*\\(?!\\)(\w)*\\(?!\\)(\w)*(?!\\)/g;

    return pattern.test(input);
  }

  /**
   * Checks if the application is running on a mobile or desktop environment.
   * https://stackoverflow.com/questions/11381673/detecting-a-mobile-browser
   * @returns True if running on mobile.
   */
  public static isMobile(): boolean
  {
    let check = false;
    (function (a)
    {
      if (/(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino/i.test(a) || /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i.test(a.substr(0, 4))) check = true;
    }(navigator.userAgent || navigator.vendor || window.opera));

    return check;
  }

  /**
   * Compare if two string arrays are equal.
   * @param array1 The first array.
   * @param array2 The second array.
   * @returns True if equal.
   */
  public static arraysAreEqual(array1: string[], array2: string[]): boolean
  {
    // Check if arrays have the same length
    if (array1.length !== array2.length)
    {
      return false;
    }

    for (let i = 0; i < array1.length; i += 1)
    {
      // If any elements are different, return false
      if (array1[i] !== array2[i])
      {
        return false;
      }
    }

    return true;
  }

  /**
   * Parses a timespan string to Timespan object.
   * @param timeSpanString Timespan string;
   * @returns Time span.
   */
  public static parseStringToTimespan(timeSpanString: string): Timespan
  {
    const timespan: Timespan = Constants.DEFAULT_TIMESPAN;

    if (timeSpanString === null || timeSpanString.trim() === '')
    {
      return timespan;
    }

    const times = timeSpanString.split(':');

    if (times.length !== 3)
    {
      logger.error('Invalid timespan provided!');
      return timespan;
    }

    const hours = times[0];
    const minutes = times[1];
    const seconds = times[2];

    if (hours !== undefined)
    {
      timespan.hours = hours;
    }

    if (minutes !== undefined)
    {
      timespan.minutes = minutes;
    }

    if (seconds !== undefined)
    {
      timespan.seconds = seconds;
    }

    return timespan;
  }

  /**
   * Cast timespan to string.
   * @param item The timespan.
   */
  public static castTimespanToString(item: Timespan): string
  {
    return `${item.hours}:${item.minutes}:${item.seconds}`;
  }

  /**
   * Returns a default boolean value as a string.
   * @param value The boolean value.
   * @returns Default value.
   */
  public static getDefaultBoolean(value?: boolean): string
  {
    if (value === undefined)
    {
      return '-';
    }

    return value ? 'Yes' : 'No';
  }

  /**
   * Returns a default number value as a string.
   * @param value The number value.
   * @returns Default value.
   */
  public static getDefaultNumber(value?: number): string
  {
    if (value === undefined)
    {
      return '-';
    }

    return value.toString();
  }

  /**
   * Format to date string.
   * @param date The date.
   */
  public static formatDateToModalString(date: Date): string
  {
    const dateString = `${date.getFullYear()}-${(date.getMonth() + 1).toString().padStart(2, '0')}-${date.getDate().toString().padStart(2, '0')}`;
    return dateString;
  }

  /**
   * Constructs a date object.
   * @param date The date.
   * @param time The time.
   */
  public static constructDateAndTime(date: Date, time: Date): Date
  {
    return new Date(
      date.getFullYear(),
      date.getMonth(),
      date.getDate(),
      time.getHours(),
      time.getMinutes(),
    );
  }

  /**
   * Constructs the list header title.
   * @param dataLength The data length.
   * @param dateRange The date range.
   */
  public static constructListHeaderTitle(dateRange: DateRange, dataLength: number): string
  {
    return `Showing ${dataLength} result(s) between:\n${dateRange.start.toLocaleString()} to ${dateRange.end.toLocaleString()}`;
  }

  /**
   * Constructs the paged header title.
   * @param dataLength The data length.
   * @param current The current page.
   * @param total The total page.
   */
  public static constructPagedHeaderTitle(
    dateRange: DateRange, current: number, total: number,
  ): string
  {
    return `Showing page ${total === 0 ? current : current + 1} of ${total} between:\n${dateRange.start.toLocaleString()} to ${dateRange.end.toLocaleString()}`;
  }

  /**
   * Convert the timespan string to seconds.
   * @param timespanString The timespan string.
   * @returns Time span string in seconds.
   */
  public static castTimespanStringToSeconds(timespanString: string): number
  {
    const timespan: Timespan = this.parseStringToTimespan(timespanString);

    const hours = parseInt(timespan.hours, 10) * 60 * 60;
    const minutes = parseInt(timespan.minutes, 10) * 60;
    const seconds = parseInt(timespan.seconds, 10);

    return hours + minutes + seconds;
  }

  /**
   * Converts seconds to timespan string.
   * @param seconds The seconds string.
   * @returns The timespan string;
   */
  public static castSecondsToTimespanString(seconds: string): string
  {
    const totalSeconds = parseInt(seconds, 10);

    if (Number.isNaN(totalSeconds))
    {
      return '00:00:00';
    }

    return this.castTimespanToString({
      hours: Math.floor(totalSeconds / 3600).toString(),
      minutes: Math.floor(totalSeconds / 60).toString(),
      seconds: (totalSeconds % 60).toString(),
    });
  }

  /**
   * Returns the websocket prefix.
   * @returns Websocket prefix.
   */
  public static getWebsocketPrefix(): string
  {
    if (window.location === undefined || window.location.protocol === undefined)
    {
      return 'wss';
    }

    return window.location.protocol.toLowerCase() === 'https:' ? 'wss' : 'ws';
  }

  /**
   * Calculates the time difference.
   * @param time1 Time from.
   * @param time2 Time to.
   * @returns Time difference.
   */
  public static calculateTimeDifference(time1: Date, time2: Date): Time
  {
    const timeDiffMs = time1.getTime() - time2.getTime();

    const millisecondsInHour: number = 1000 * 60 * 60; // Number of milliseconds in an hour
    const millisecondsInMinute: number = 1000 * 60; // Number of milliseconds in a minute

    // Calculate hours and minutes
    const hours: number = Math.floor(timeDiffMs / millisecondsInHour);
    const remainingMilliseconds: number = timeDiffMs % millisecondsInHour;
    const minutes: number = Math.floor(remainingMilliseconds / millisecondsInMinute);

    return {
      hours,
      minutes,
    };
  }

  /**
   * Get the local time from ISO date.
   * @param date The date.
   * @returns Time.
   */
  public static getLocalTime(date: Date): Time
  {
    return {
      hours: date.getHours(),
      minutes: date.getMinutes(),
    };
  }

  /**
   * Delay.
   * @param ms The delay in milliseconds.
   * @returns
   */
  public static delay(ms?: number): Promise<void>
  {
    return new Promise((res) => setTimeout(res, ms));
  }
}
