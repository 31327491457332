/* eslint-disable class-methods-use-this */
import { decorate, observable } from 'mobx';
import Users from '../Models/Users';

/**
 * Users Repository.
 */
export class UserManagementRepository
{
  /**
   * The available users.
   */
  public all: Users[] = [];

  /**
   * The filtered user.
   */
  public filteredUser = '';

  /**
   * Replaces all the users.
   * @param all The users.
   */
  public replace(all: Users[]): void
  {
    this.all = all;
  }

  /**
   * Adds or updates the repository.
   * @param user The user to add or update.
   */
  public addOrUpdate(user: Users): void
  {
    const index = this.all.findIndex((x) => x.id === user.id);

    if (index === -1)
    {
      this.all.push(user);
      return;
    }

    this.all[index] = user;
  }

  /**
   * Remove.
   * @param id The id.
   */
  public remove(id: string): void
  {
    const clone = [...this.all];
    const filtered = clone.filter((x) => x.id !== id);
    this.all = filtered;
  }

  /**
   * Get the area by id.
   * @param id The id.
   * @returns The area response.
   */
  public getById(id: string): Users | undefined
  {
    return this.all.find((x) => x.id === id);
  }

  /**
   * Clear all.
   */
  public clearAll(): void
  {
    this.all.length = 0;
  }
}

decorate(UserManagementRepository, {
  all: observable,
  filteredUser: observable,
});

export const userManagementRepository = new UserManagementRepository();
