import React, { useMemo, useState } from 'react';
import {
  ActivityIndicator,
  StyleSheet,
  Text,
  TextInput,
  TouchableOpacity,
  View,
} from 'react-native';
import { useObserver } from 'mobx-react-lite';
import { RFPercentage, RFValue } from 'react-native-responsive-fontsize';
import { LinearGradient } from 'expo-linear-gradient';
import Fonts from '../../../../Domain/Types/Fonts';
import { responsiveScreenRepository } from '../../../../Domain/Repositories/ResponsiveScreenRepository';
import ModalViewBase from '../ModalViewBase';
import ModalType from '../../../../Domain/Types/ModalType';
import { Colors } from '../../../../Config/Colors';
import { BaseModalType, modalController } from '../ModalController';
import INotificationModalData from '../../../../Domain/Models/INotificationModalData';
import { userManagementRepository } from '../../../../Domain/Repositories/UserManagementRepository';
import EditRoleModalData from '../../../../Domain/Models/EditRoleModalData';
import IConfirmationDialogModel from '../../../../Domain/Models/IConfirmationDialogModel';
import SsoService from '../../../../Domain/Services/SsoService';

interface CreateAreaModalProps
{
  /**
   * The maximum characters.
   */
  maxCharacters?: number;
}

/**
 * The Edit User Modal.
 * @returns JSX.Element.
 */
export default function EditUserModal({
  maxCharacters = 100,
}: CreateAreaModalProps): JSX.Element
{
  const [name, setName] = useState('');
  const [loading, setLoading] = useState(false);

  const closeModal = (): void =>
  {
    modalController.hide();
  };

  const onNameUpdated = (updatedName: string): void =>
  {
    const truncated = updatedName.substring(0, maxCharacters);
    setName(truncated);
  };

  const onSave = async (): Promise<void> =>
  {
    if (name.trim() === '')
    {
      showFailedModal('You must enter an user name to proceed!');
      return;
    }

    // Check for duplicates
    const usernames = userManagementRepository.all.map((x) => x.userName.toLowerCase());

    if (usernames.includes(name.toLowerCase()))
    {
      showFailedModal('A user with this name already exists!\nTry again with another name.');
      return;
    }

    const data = modalController.activeModal.data as EditRoleModalData;

    if (data === undefined)
    {
      showFailedModal('No user was selected!');
      return;
    }

    const response = await SsoService.updateUsername(data.id, name);

    if (!response.success)
    {
      showFailedModal(response.reason);
      return;
    }

    showSuccessModal('Username updated succesfully!');
  };

  const onDelete = async (): Promise<void> =>
  {
    const modalData = modalController.activeModal.data as EditRoleModalData;

    if (modalData === undefined)
    {
      showFailedModal('No user was selected!');
      return;
    }

    const data: IConfirmationDialogModel = {
      message: 'Are you sure you want to delete this user?',
      title: 'Info',
      positiveAction: async () =>
      {
        const response = await SsoService.delete(modalData.id);
        if (!response.success)
        {
          showFailedModal(response.reason);
          return;
        }

        showSuccessModal('Succesfully deleted user!');
      },
      negativeAction: (): void => modalController.showPrevious(),
    };

    modalController.show({
      modalType: BaseModalType.GenericConfirmation,
      data,
    });
  };

  const showFailedModal = (reason: string): void =>
  {
    const notification: INotificationModalData = {
      title: 'Error!',
      message: reason,
      disableCloseOnConfirm: true,
      onConfirmButtonPressed: (): void => modalController.showPrevious(),
    };

    modalController.show({
      modalType: BaseModalType.SimpleNotification,
      data: notification,
    });
  };

  const showSuccessModal = (message: string): void =>
  {
    const notification: INotificationModalData = {
      title: 'Info',
      message,
    };

    modalController.show({
      modalType: BaseModalType.SimpleNotification,
      data: notification,
    });
  };

  const onModalShown = (): void =>
  {
    const data = modalController.activeModal.data as EditRoleModalData;

    if (data === undefined)
    {
      setName('');
      return;
    }

    const user = userManagementRepository.getById(data.id);

    if (user === undefined)
    {
      setName('');
      return;
    }

    setName(user.userName);
  };

  const reset = (): void =>
  {
    setName('');
    setLoading(false);
  };

  const styles = useMemo(() => StyleSheet.create({
    content: {
      height: RFPercentage(24),
      width: undefined,
      aspectRatio: 1.8,
      backgroundColor: Colors.PRIMARY(),
      shadowColor: Colors.APP.teal,
      shadowOffset: {
        width: RFValue(1.1),
        height: RFValue(1.1),
      },
      shadowOpacity: 1,
      shadowRadius: RFValue(0.5),
      zIndex: 10,
    },
    container: {
      height: '100%',
      width: '100%',
    },
    titleView: {
      flex: 0.2,
      width: '100%',
      justifyContent: 'flex-end',
      alignItems: 'center',
    },
    titleText: {
      textAlign: 'left',
      fontSize: RFValue(11),
      color: 'white',
      fontFamily: Fonts.JudgeMedium,
      textTransform: 'uppercase',
    },
    nameInputView: {
      flex: 0.6,
      width: '100%',
      justifyContent: 'center',
      alignItems: 'center',
    },
    input: {
      height: '30%',
      width: '50%',
      backgroundColor: Colors.BLACK(0.15),
      color: 'white',
      paddingHorizontal: '5%',
      fontSize: RFValue(11),
      fontFamily: Fonts.JudgeMedium,
      textAlign: 'center',
    },
    message: {
      color: Colors.APP.red,
      fontSize: RFValue(11),
      fontFamily: Fonts.JudgeMedium,
      textAlign: 'center',
      paddingTop: '1%',
    },
    closeView: {
      flex: 0.2,
      width: '100%',
      justifyContent: 'center',
      alignItems: 'center',
      flexDirection: 'row-reverse',
    },
    button: {
      width: '28%',
      height: '70%',
      alignItems: 'center',
      justifyContent: 'center',
      marginBottom: '5%',
      marginHorizontal: '1%',
    },
    buttonContent: {
      width: '100%',
      height: '100%',
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'center',
    },
    buttonTextView: {
      width: '100%',
      height: '100%',
      alignItems: 'center',
      justifyContent: 'center',
    },
    buttonText: {
      width: '95%',
      textAlign: 'center',
      fontFamily: Fonts.JudgeMedium,
      fontSize: RFValue(11),
      color: 'white',
    },
  }), [responsiveScreenRepository.key]);

  return useObserver(() => (
    <ModalViewBase
      onModalShown={onModalShown}
      onModalHidden={reset}
      modalType={ModalType.EditUserModal}
      closeModalOnBackgroundTouched={false}
    >
      <View style={styles.content}>
        <View style={styles.container}>
          <View style={styles.titleView}>
            <Text style={styles.titleText}>
              Edit User
            </Text>
          </View>

          <View style={styles.nameInputView}>
            <TextInput
              style={styles.input}
              keyboardType="email-address"
              placeholder="Update username"
              autoCompleteType="off"
              placeholderTextColor="white"
              onChangeText={onNameUpdated}
              value={name}
              maxLength={maxCharacters}
            />
          </View>

          {!loading ? (
            <View style={styles.closeView}>
              <TouchableOpacity
                style={styles.button}
                onPress={onDelete}
              >
                <LinearGradient
                  colors={[Colors.APP.red, Colors.APP.red]}
                  style={styles.buttonContent}
                >
                  <View style={styles.buttonTextView}>
                    <Text style={styles.buttonText}>
                      Delete User
                    </Text>
                  </View>
                </LinearGradient>
              </TouchableOpacity>

              <TouchableOpacity
                style={styles.button}
                disabled={name.trim() === ''}
                onPress={onSave}
              >
                <LinearGradient
                  colors={name.trim() === '' ? Colors.LOGIN().inactive : Colors.LOGIN().active}
                  style={styles.buttonContent}
                >
                  <View style={styles.buttonTextView}>
                    <Text style={styles.buttonText}>
                      Save Changes
                    </Text>
                  </View>
                </LinearGradient>
              </TouchableOpacity>

              <TouchableOpacity
                style={styles.button}
                onPress={closeModal}
              >
                <LinearGradient
                  colors={Colors.LOGIN().active}
                  style={styles.buttonContent}
                >
                  <View style={styles.buttonTextView}>
                    <Text style={styles.buttonText}>
                      Cancel
                    </Text>
                  </View>
                </LinearGradient>
              </TouchableOpacity>
            </View>
          ) : (
            <View style={styles.closeView}>
              <ActivityIndicator color={Colors.APP.teal} size="large" />
            </View>
          )}
        </View>
      </View>
    </ModalViewBase>
  ));
}
