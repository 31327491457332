/* eslint-disable import/prefer-default-export */

interface LoginColor
{
  active: string[];
  inactive: string[];
}

const APP = {
  background: ['black', '#0A0050', '#0A0050', '#0A0050'],
  red: 'rgb(229, 1, 56)',
  green: 'rgb(55, 179, 74)',
  blue: 'rgb(59, 100, 148)',
  darkerBlue: 'rgb(16, 17, 45)',
  darkBlue: 'rgb(10, 0, 80)',
  teal: 'rgb(19, 198, 222)',
  gold: 'rgb(213, 187, 120)',
};

const APP_OPACITY = (opacity = 1) => ({
  background: [
    `rgba(0, 0, 0, ${opacity})`,
    `rgba(10, 0, 80, ${opacity})`,
    `rgba(10, 0, 80, ${opacity})`,
    `rgba(10, 0, 80, ${opacity})`,
  ],
  red: `rgba(229, 1, 56, ${opacity})`,
  green: `rgba(55, 179, 74, ${opacity})`,
  blue: `rgba(59, 100, 148, ${opacity})`,
  darkerBlue: `rgba(16, 17, 45, ${opacity})`,
  darkBlue: `rgba(10, 0, 80, ${opacity})`,
  teal: `rgba(19, 198, 222, ${opacity})`,
  gold: `rgba(213, 187, 120, ${opacity})`,
});

const PRIMARY = (opacity = 1): string => `rgba(36, 36, 36, ${opacity})`;

const BLACK = (opacity = 1): string => `rgba(0, 0, 0, ${opacity})`;

const LOGIN = (): LoginColor => ({
  active: [APP.teal, APP.teal],
  inactive: [BLACK(0.25), BLACK(0.25)],
});

const DROPDOWN = {
  icon: 'white',
  border: 'transparent',
  unselected: '#393838',
  selected: APP.teal,
};

/**
 * The Cube Colors.
 */
export const Colors = {
  LOGIN,
  DROPDOWN,
  BLACK,
  PRIMARY,
  APP,
  APP_OPACITY,
};
