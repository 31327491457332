import UrbanPlayground from '../Images/Icons/UrbanPlaygroundIcon.png';
import LogOut from '../Images/Icons/Logout.png';
import Back from '../Images/Icons/Back.png';
import Edit from '../Images/Icons/Edit.png';
import Exit from '../Images/Icons/Exit.png';

/**
 * The Application Icons.
 */
const Icons = {
  UrbanPlayground,
  Back,
  LogOut,
  Edit,
  Exit,
};

export default Icons;
