import React from 'react';
import { useObserver } from 'mobx-react-lite';
import ConfirmationDialogModalBase from '../ConfirmationDialogModalBase/ConfirmationDialogModalBase';
import { modalController, BaseModalType } from '../ModalController';
import ModalViewBase from '../ModalViewBase';
import IConfirmationDialogModel from '../../../../Domain/Models/IConfirmationDialogModel';

/**
 * Generic Confirmation Modal
 * @returns JSX.Element.
 */
export default function GenericConfirmationModal(): JSX.Element
{
  const onPositivePressed = (): void =>
  {
    const data = modalController.activeModal.data as IConfirmationDialogModel;

    if (data !== undefined && data.positiveAction !== undefined)
    {
      data.positiveAction();
    }
  };

  const onNegativePressed = (): void =>
  {
    const data = modalController.activeModal.data as IConfirmationDialogModel;

    if (data !== undefined && data.negativeAction !== undefined)
    {
      data.negativeAction();
    }
  };

  const getTitle = (): string =>
  {
    const data = modalController.activeModal.data as IConfirmationDialogModel;
    return data !== undefined && data.title !== undefined ? data.title : 'Caution!';
  };

  const getMessage = (): string =>
  {
    const data = modalController.activeModal.data as IConfirmationDialogModel;

    if (data === undefined)
    {
      return '';
    }

    return data.message === undefined ? '' : data.message;
  };

  return useObserver(() => (
    <ModalViewBase modalType={BaseModalType.GenericConfirmation}>
      <ConfirmationDialogModalBase
        title={getTitle()}
        onPositivePressed={onPositivePressed}
        onNegativePressed={onNegativePressed}
        message={getMessage()}
        errorMessage={modalController.genericConfirmationModalError}
      />
    </ModalViewBase>
  ));
}
