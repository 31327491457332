/* eslint-disable class-methods-use-this */
import { decorate, observable } from 'mobx';
import { getBaseOs } from 'react-native-device-info';
import Logger from '../Logger/Logger';
import Utils from '../Utils/Utils';
import SessionState from '../Models/SessionState';
import Environment from '../Models/Environment';
import OidConfig from '../Models/OidConfig';

const logger = Logger.Create('AppRepository');

/**
 * Application Repository.
 */
export class AppRepository
{
  /**
   * Flag to indicate if the application is running on mobile.
   */
  public isMobile = false;

  /**
   * The currently detected base os name.
   */
  public deviceBaseOs = '';

  /**
   * The inactivity.
   */
  public resetInactivity = false;

  /**
   * The data retrieval state.
   */
  public dataRetrievalCompleted: SessionState = {
    didComplete: false,
    failed: false,
  };

  /**
   * The environment.
   */
  public environment: Environment | undefined = undefined; 

  /**
   * The Oid Configuration.
   */
  public oidConfig: OidConfig | undefined = undefined;

  /**
   * Sets the runtime parameters.
   * @param environment The environment.
   * @param oidConfig The oid config.
   */
  public setRuntimeParameters(environment: Environment, oidConfig: OidConfig): void
  {
    this.environment = environment;
    this.oidConfig = oidConfig;
    logger.info('Successfully set the runtime parameters.');
  }

  /**
   * Returns the server url.
   * @returns Server url.
   */
  public getServerUrl(): string
  {
    const url = this.environment?.apiBase;

    if (url === undefined)
    {
      return window.location.origin;
    }

    return url;
  }

  /**
   * Returns the websocket url.
   * @returns The websocket url.
   */
  public getWebsocketUrl(): string
  {
    const url = this.environment?.wsBase;

    const wsPostfix = 'websockets';

    if (url !== undefined)
    {
      return `${url}/${wsPostfix}`;
    }

    const wsPrefix = Utils.getWebsocketPrefix();
    const { host } = window.location;

    return `${wsPrefix}://${host}/${wsPostfix}`;
  };

  /**
   * Checks the environment the application is running in.
   */
  public async checkEnvironmentPlatform(): Promise<void>
  {
    logger.info('Attempting to check the device platform...');

    await getBaseOs()
      .then((result: string) =>
      {
        const os = result.toLowerCase().replace(/\s+/g, '');
        this.deviceBaseOs = os;
        this.isMobile = !(os === 'macos' || os === 'windows');
      })
      .catch(() =>
      {
        logger.error('Unable to retrieve the baseOs for this device.');
        this.isMobile = false;
      });
  }

  /**
   * Reset the inactivity flag;
   */
  public resetInactivityFlag(): void
  {
    this.resetInactivity = !this.resetInactivity;
  }
}

decorate(AppRepository, {
  dataRetrievalCompleted: observable,
  resetInactivity: observable,
});

export const appRepository = new AppRepository();
