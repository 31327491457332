/**
 * The Modal Types.
 */
enum ModalType
{
  AddUserModal = 'AddUserModal',
  EditUserModal = 'EditUserModal',
  EditRoleModal = 'EditRoleModal',
}

export default ModalType;
