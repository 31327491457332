/* eslint-disable import/prefer-default-export */
import { Dimensions } from 'react-native';
import Users from '../Domain/Models/Users';

const DEFAULT_USER: Users = {
  id: '',
  roles: [],
  userName: '',
};

const { width: SCREEN_WIDTH, height: SCREEN_HEIGHT } = Dimensions.get('screen');

const { width: WINDOW_WIDTH, height: WINDOW_HEIGHT } = Dimensions.get('window');

export const Constants = {
  SCREEN_HEIGHT,
  SCREEN_WIDTH,
  WINDOW_HEIGHT,
  WINDOW_WIDTH,
  DEFAULT_USER,
};
