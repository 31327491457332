import React, { useMemo, useState } from 'react';
import {
  ActivityIndicator,
  StyleSheet,
  Text,
  TouchableOpacity,
  View,
} from 'react-native';
import { useObserver } from 'mobx-react-lite';
import { RFPercentage, RFValue } from 'react-native-responsive-fontsize';
import { LinearGradient } from 'expo-linear-gradient';
import { Dropdown } from 'react-native-element-dropdown';
import Fonts from '../../../../Domain/Types/Fonts';
import { responsiveScreenRepository } from '../../../../Domain/Repositories/ResponsiveScreenRepository';
import ModalViewBase from '../ModalViewBase';
import ModalType from '../../../../Domain/Types/ModalType';
import { Colors } from '../../../../Config/Colors';
import { BaseModalType, modalController } from '../ModalController';
import INotificationModalData from '../../../../Domain/Models/INotificationModalData';
import DropdownModel from '../../../../Domain/Models/DropdownModel';
import EditRoleModalData from '../../../../Domain/Models/EditRoleModalData';
import Roles from '../../../../Domain/Types/Roles';
import SsoService from '../../../../Domain/Services/SsoService';

const MAXIMUM_ITEMS_PER_VIEW = 5;
const ITEM_HEIGHT = RFValue(25);
const MAXIMUM_HEIGHT = MAXIMUM_ITEMS_PER_VIEW * ITEM_HEIGHT;

/**
 * The Edit Role Modal.
 * @returns JSX.Element.
 */
export default function EditRoleModal(): JSX.Element
{
  const [loading, setLoading] = useState(false);
  const [selectedRole, setSelectedRole] = useState<DropdownModel | null>(null);

  const closeModal = (): void =>
  {
    modalController.hide();
  };

  const onSave = async (): Promise<void> =>
  {
    if (selectedRole === null)
    {
      showFailedModal('No role was selected!');
      return;
    }

    const data = modalController.activeModal.data as EditRoleModalData;

    if (data === undefined)
    {
      showFailedModal('No user was selected!');
      return;
    }

    const { id } = data;
    const response = await SsoService.updateRole(id, selectedRole.value);

    if (!response.success)
    {
      showFailedModal(response.reason);
      return;
    }

    const notification: INotificationModalData = {
      title: 'Info',
      message: `Successfully updated the role to "${selectedRole.value}"`,
    };

    modalController.show({
      modalType: BaseModalType.SimpleNotification,
      data: notification,
    });
  };

  const showFailedModal = (reason: string): void =>
  {
    const notification: INotificationModalData = {
      title: 'Error!',
      message: reason,
      disableCloseOnConfirm: true,
      onConfirmButtonPressed: (): void => modalController.showPrevious(),
    };

    modalController.show({
      modalType: BaseModalType.SimpleNotification,
      data: notification,
    });
  };

  const reset = (): void =>
  {
    setSelectedRole(null);
    setLoading(false);
  };

  const constructDropdown = (): DropdownModel[] =>
  {
    const dropdownItems: DropdownModel[] = Object.values(Roles).map((x) => ({
      label: x,
      value: x,
    }));

    return dropdownItems;
  };

  const onChange = (item: DropdownModel): void =>
  {
    setSelectedRole(item);
  };

  const styles = useMemo(() => StyleSheet.create({
    content: {
      height: RFPercentage(20),
      width: undefined,
      aspectRatio: 2,
      backgroundColor: Colors.PRIMARY(),
      shadowColor: Colors.APP.teal,
      shadowOffset: {
        width: RFValue(1.1),
        height: RFValue(1.1),
      },
      shadowOpacity: 1,
      shadowRadius: RFValue(0.5),
      zIndex: 10,
    },
    container: {
      height: '100%',
      width: '100%',
    },
    titleView: {
      flex: 0.2,
      width: '100%',
      justifyContent: 'flex-end',
      alignItems: 'center',
    },
    titleText: {
      textAlign: 'left',
      fontSize: RFValue(11),
      color: 'white',
      fontFamily: Fonts.JudgeMedium,
      textTransform: 'uppercase',
    },
    nameInputView: {
      flex: 0.6,
      width: '100%',
      justifyContent: 'center',
      alignItems: 'center',
    },
    dropdown: {
      backgroundColor: Colors.DROPDOWN.unselected,
      width: '50%',
      height: '10%',
      padding: '5%',
      borderColor: Colors.DROPDOWN.border,
      borderWidth: RFValue(1),
    },
    dropdownPlaceholderText: {
      textAlign: 'center',
      fontSize: RFValue(10),
      fontFamily: Fonts.JudgeMedium,
      width: '70%',
      color: 'white',
    },
    dropdownSelectedText: {
      textAlign: 'center',
      fontSize: RFValue(10),
      fontFamily: Fonts.JudgeMedium,
      color: 'white',
      marginLeft: '5%',
    },
    dropdownListContainer: {
      backgroundColor: Colors.DROPDOWN.unselected,
      overflow: 'hidden',
      borderColor: Colors.DROPDOWN.border,
      borderWidth: RFValue(1),
    },
    dropdownListItemContainer: {
      height: ITEM_HEIGHT,
      justifyContent: 'center',
    },
    dropdownListItemText: {
      fontSize: RFValue(10),
      fontFamily: Fonts.JudgeMedium,
      textAlign: 'center',
      justifyContent: 'center',
      color: 'white',
    },
    dropdownArrowIcon: {
      width: RFValue(10),
      height: undefined,
      aspectRatio: 1,
      position: 'absolute',
      right: 0,
    },
    closeView: {
      flex: 0.2,
      width: '100%',
      justifyContent: 'center',
      alignItems: 'center',
      flexDirection: 'row-reverse',
    },
    button: {
      width: '30%',
      height: '70%',
      alignItems: 'center',
      justifyContent: 'center',
      marginBottom: '5%',
      marginHorizontal: '5%',
    },
    buttonContent: {
      width: '100%',
      height: '100%',
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'center',
    },
    buttonTextView: {
      width: '100%',
      height: '100%',
      alignItems: 'center',
      justifyContent: 'center',
    },
    buttonText: {
      width: '95%',
      textAlign: 'center',
      fontFamily: Fonts.JudgeMedium,
      fontSize: RFValue(11),
      color: 'white',
    },
  }), [responsiveScreenRepository.key]);

  return useObserver(() => (
    <ModalViewBase
      onModalHidden={reset}
      modalType={ModalType.EditRoleModal}
      closeModalOnBackgroundTouched={false}
    >
      <View style={styles.content}>
        <View style={styles.container}>
          <View style={styles.titleView}>
            <Text style={styles.titleText}>
              Edit Role
            </Text>
          </View>

          <View style={styles.nameInputView}>
            <Dropdown
              placeholder="Select role"
              style={[styles.dropdown, {
                opacity: loading ? 0.2 : 1,
              }]}
              placeholderStyle={styles.dropdownPlaceholderText}
              selectedTextStyle={styles.dropdownSelectedText}
              containerStyle={styles.dropdownListContainer}
              itemContainerStyle={styles.dropdownListItemContainer}
              itemTextStyle={styles.dropdownListItemText}
              data={constructDropdown()}
              maxHeight={MAXIMUM_HEIGHT}
              labelField="label"
              valueField="value"
              value={selectedRole}
              activeColor={Colors.DROPDOWN.selected}
              iconStyle={styles.dropdownArrowIcon}
              iconColor={Colors.DROPDOWN.icon}
              onChange={onChange}
              disable={loading}
            />
          </View>

          {!loading ? (
            <View style={styles.closeView}>
              <TouchableOpacity
                style={styles.button}
                disabled={selectedRole === null}
                onPress={onSave}
              >
                <LinearGradient
                  colors={selectedRole === null
                    ? Colors.LOGIN().inactive : Colors.LOGIN().active}
                  style={styles.buttonContent}
                >
                  <View style={styles.buttonTextView}>
                    <Text style={styles.buttonText}>
                      Save Changes
                    </Text>
                  </View>
                </LinearGradient>
              </TouchableOpacity>

              <TouchableOpacity
                style={styles.button}
                onPress={closeModal}
              >
                <LinearGradient
                  colors={Colors.LOGIN().active}
                  style={styles.buttonContent}
                >
                  <View style={styles.buttonTextView}>
                    <Text style={styles.buttonText}>
                      Cancel
                    </Text>
                  </View>
                </LinearGradient>
              </TouchableOpacity>
            </View>
          ) : (
            <View style={styles.closeView}>
              <ActivityIndicator color={Colors.APP.teal} size="large" />
            </View>
          )}
        </View>
      </View>
    </ModalViewBase>
  ));
}
