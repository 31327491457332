/**
 * The available fonts.
 */
enum Fonts
{
  BebasNeue = 'BebasNeue',

  // Poppins
  PoppinsExtraBoldItalic = 'PoppinsExtraBoldItalic',
  PoppinsBlack = 'PoppinsBlack',
  PoppinsBlackItalic = 'PoppinsBlackItalic',
  PoppinsBold = 'PoppinsBold',
  PoppinsBoldItalic = 'PoppinsBoldItalic',
  PoppinsExtraBold = 'PoppinsExtraBold',
  PoppinsExtraLight = 'PoppinsExtraLight',
  PoppinsExtraLightItalic = 'PoppinsExtraLightItalic',
  PoppinsItalic = 'PoppinsItalic',
  PoppinsLight = 'PoppinsLight',
  PoppinsLightItalic = 'PoppinsLightItalic',
  PoppinsMedium = 'PoppinsMedium',
  PoppinsMediumItalic = 'PoppinsMediumItalic',
  PoppinsRegular = 'PoppinsRegular',
  PoppinsSemiBold = 'PoppinsSemiBold',
  PoppinsSemiBoldItalic = 'PoppinsSemiBoldItalic',
  PoppinsThin = 'PoppinsThin',
  PoppinsThinItalic = 'PoppinsThinItalic',

  // Montserrat
  MontserratBlack = 'MontserratBlack',
  MontserratBlackItalic = 'MontserratBlackItalic',
  MontserratBold = 'MontserratBold',
  MontserratBoldItalic = 'MontserratBoldItalic',
  MontserratExtraBold = 'MontserratExtraBold',
  MontserratExtraBoldItalic = 'MontserratExtraBoldItalic',
  MontserratExtraLight = 'MontserratExtraLight',
  MontserratExtraLightItalic = 'MontserratExtraLightItalic',
  MontserratItalic = 'MontserratItalic',
  MontserratLight = 'MontserratLight',
  MontserratLightItalic = 'MontserratLightItalic',
  MontserratMedium = 'MontserratMedium',
  MontserratMediumItalic = 'MontserratMediumItalic',
  MontserratRegular = 'MontserratRegular',
  MontserratSemiBold = 'MontserratSemiBold',
  MontserratSemiBoldItalic = 'MontserratSemiBoldItalic',
  MontserratThin = 'MontserratThin',
  MontserratThinItalic = 'MontserratThinItalic',

  // Din
  DDINItalic = 'DDINItalic',
  DDIN = 'DDIN',
  DDINBold = 'DDINBold',
  DDINCondensedBold = 'DDINCondensedBold',
  DDINCondensed = 'DDINCondensed',
  DDINExp = 'DDINExp',
  DDINExpBold = 'DDINExpBold',
  DDINExpItalic = 'DDINExpItalic',

  // TheCube2020.
  TheCube2020Regular = 'TheCube2020Regular',
  TheCube2020Outline = 'TheCube2020Outline',

  // Judge
  JudgeMedium = 'JudgeMedium',

  // Londrina
  LondrinaOutlineRegular = 'LondrinaOutlineRegular',
}

export default Fonts;
