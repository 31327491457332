/**
 * The available screen types.
 */
enum ScreenTypes
{
  Login = '',
  ResetPassword = 'ResetPassword',
  UserManagement = 'UserManagement',
}

export default ScreenTypes;
