import React, {
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import {
  View,
  StyleSheet,
  Text,
  TouchableOpacity,
  TextInput,
  ActivityIndicator,
  NativeSyntheticEvent,
  TextInputKeyPressEventData,
} from 'react-native';
import { RFPercentage, RFValue } from 'react-native-responsive-fontsize';
import { LinearGradient } from 'expo-linear-gradient';
import { Image } from 'expo-image';
import { useObserver } from 'mobx-react-lite';
import useDetectKeyPress from '../../Domain/Hooks/useDetectKeyPress';
import useNavigation from '../../Domain/Hooks/useNavigation';
import { responsiveScreenRepository } from '../../Domain/Repositories/ResponsiveScreenRepository';
import { appRepository } from '../../Domain/Repositories/AppRepository';
import SsoService from '../../Domain/Services/SsoService';
import INotificationModalData from '../../Domain/Models/INotificationModalData';
import { BaseModalType, modalController } from '../Shared/Modals/ModalController';
import Fonts from '../../Domain/Types/Fonts';
import { Colors } from '../../Config/Colors';
import SimpleNotificationModal from '../Shared/Modals/SimpleNotificationModal/SimpleNotificationModal';
import ScreenTypes from '../../Domain/Types/ScreenTypes';
import Icons from '../../Assets/Icons';
import SessionService from '../../Domain/Services/SessionService';

/**
 * Renders the Login Screen.
 * @returns JSX.Element.
 */
export default function LoginScreen(): JSX.Element
{
  const {
    navigateToScreen,
  } = useNavigation();
  const [passwordModalVisible, setPasswordModalVisible] = useState(false);

  const [name, setName] = useState('');
  const [password, setPassword] = useState('');
  const [loginMessage, setLoginMessage] = useState('');
  const [valid, setValid] = useState(false);
  const [loading, setLoading] = useState(false);
  const nameRef = useRef('');
  const passwordRef = useRef('');

  useDetectKeyPress(['enter'], async (): Promise<void> =>
  {
    await onLoginPressed();
  });

  const onInputEnterPressed = async (
    keyPressEvent: NativeSyntheticEvent<TextInputKeyPressEventData>,
  ): Promise<void> =>
  {
    if (keyPressEvent.nativeEvent.key === undefined)
    {
      return;
    }

    if (keyPressEvent.nativeEvent.key.toLowerCase() === 'enter')
    {
      await onLoginPressed();
    }
  };

  useEffect(() =>
  {
    if (responsiveScreenRepository.loaded)
    {
      return;
    }

    appRepository.dataRetrievalCompleted = {
      didComplete: true,
      failed: false,
    };
  }, []);

  useEffect(() =>
  {
    SessionService.logout();
  }, []);

  const onLoginPressed = async (): Promise<void> =>
  {
    if (nameRef.current === null || passwordRef.current === null || nameRef.current.trim() === '' || passwordRef.current.trim() === '')
    {
      setLoginMessage('The mandatory name and/or password field is required!');
      return;
    }

    setLoading(true);

    const response = await SsoService.login(nameRef.current, passwordRef.current);
    setLoading(false);

    if (response.success)
    {
      navigateToScreen(ScreenTypes.UserManagement);
      return;
    }

    if (!response.success && response.reason === 'password_reset_required')
    {
      showPasswordResetNotification();
      return;
    }

    setLoginMessage(response.reason);
  };

  useEffect(() =>
  {
    nameRef.current = name;
    passwordRef.current = password;

    setValid(name.trim() !== '' && password.trim() !== '');
  }, [name, password]);

  const onNameChanged = (input: string): void =>
  {
    setName(input);
    setLoginMessage('');
  };

  const onPasswordChanged = (input: string): void =>
  {
    setPassword(input);
    setLoginMessage('');
  };

  const onForgotPasswordPressed = (): void =>
  {
    setPasswordModalVisible(true);

    const notification: INotificationModalData = {
      title: 'Info',
      message: 'You will need to contact a site supervisor or manager to change your password.',
    };

    modalController.show({
      modalType: BaseModalType.SimpleNotification,
      data: notification,
    });
  };

  const showPasswordResetNotification = (): void =>
  {
    setPasswordModalVisible(true);

    const notification: INotificationModalData = {
      title: 'Password Reset',
      message: 'Your password has been reset by your administrator. You will now be redirected to change your password.',
      onConfirmButtonPressed: (): void => navigateToScreen(ScreenTypes.ResetPassword),
    };

    modalController.show({
      modalType: BaseModalType.SimpleNotification,
      data: notification,
    });
  };

  const styles = useMemo(() => StyleSheet.create({
    scrollViewContainer: {
      alignItems: 'center',
      justifyContent: 'center',
    },
    contentContainer: {
      flex: 1,
      width: '100%',
    },
    icon: {
      width: undefined,
      height: RFValue(50),
      aspectRatio: 1.5,
      maxHeight: '50%',
    },
    loginContainer: {
      flex: 1,
      width: '100%',
      alignItems: 'center',
      justifyContent: 'center',
    },
    loginView: {
      height: RFValue(165),
      width: undefined,
      aspectRatio: 1,
      backgroundColor: Colors.APP.darkBlue,
      shadowColor: Colors.APP.teal,
      shadowOffset: {
        width: RFValue(2.5),
        height: RFValue(2.5),
      },
      shadowOpacity: 1,
      shadowRadius: RFValue(0.5),
      borderWidth: RFValue(1),
      borderColor: 'white',
    },
    titleView: {
      flex: 0.2,
      width: '100%',
      justifyContent: 'flex-end',
      alignItems: 'center',
    },
    titleText: {
      width: '95%',
      textAlign: 'center',
      fontFamily: Fonts.JudgeMedium,
      fontSize: RFValue(10),
      color: 'white',
      textTransform: 'uppercase',
      paddingBottom: RFValue(3),
      fontWeight: 'bold',
      letterSpacing: RFValue(0.8),
    },
    loginContent: {
      flex: 0.6,
      width: '100%',
      justifyContent: 'flex-start',
      alignItems: 'center',
    },
    input: {
      marginTop: '10%',
      height: RFValue(23),
      width: RFValue(115),
      backgroundColor: Colors.BLACK(0.25),
      color: 'white',
      padding: '5%',
      fontSize: RFValue(10),
      fontFamily: Fonts.JudgeMedium,
      borderLeftWidth: RFValue(1),
      borderLeftColor: Colors.APP.teal,
    },
    inputPadding: {
      marginTop: RFValue(10),
    },
    loginButtonView: {
      flex: 0.2,
      width: '100%',
      justifyContent: 'center',
      alignItems: 'center',
    },
    loginButtonContainer: {
      flex: 0.6,
      width: '100%',
      justifyContent: 'center',
      alignItems: 'center',
    },
    button: {
      width: RFValue(115),
      height: RFValue(20),
      alignItems: 'center',
      justifyContent: 'center',
    },
    buttonContent: {
      width: '100%',
      height: '100%',
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'center',
    },
    buttonTextView: {
      width: '100%',
      height: '100%',
      alignItems: 'center',
      justifyContent: 'center',
    },
    buttonText: {
      width: '95%',
      textAlign: 'center',
      fontFamily: Fonts.JudgeMedium,
      fontSize: RFValue(9),
      color: 'white',
      fontWeight: 'bold',
      letterSpacing: RFValue(0.8),
    },
    loginTextContainer: {
      flex: 0.4,
      width: '100%',
      justifyContent: 'flex-start',
      alignItems: 'center',
    },
    loginText: {
      width: '95%',
      textAlign: 'center',
      fontFamily: Fonts.JudgeMedium,
      fontSize: RFValue(6),
      color: Colors.APP.red,
      marginTop: RFValue(3),
    },
    forgotPasswordContainer: {
      position: 'absolute',
      bottom: RFPercentage(-4),
      height: RFPercentage(3),
      width: '100%',
      justifyContent: 'flex-start',
      alignItems: 'center',
    },
    forgottenPasswordButton: {
      width: '100%',
      height: '100%',
      alignItems: 'center',
      justifyContent: 'flex-start',
    },
    forgottenPasswordText: {
      width: '95%',
      textAlign: 'center',
      fontFamily: Fonts.JudgeMedium,
      fontSize: RFValue(6),
      color: Colors.APP.teal,
      textTransform: 'uppercase',
      textDecorationLine: 'underline',
      letterSpacing: RFValue(0.5),
      marginTop: '0.5%',
      fontWeight: 'bold',
    },
  }), [responsiveScreenRepository.key]);

  return useObserver(() => (
    <View style={{
      width: responsiveScreenRepository.newWidth,
      height: responsiveScreenRepository.newHeight,
      backgroundColor: Colors.APP.darkBlue,
    }}
    >
      <View style={styles.contentContainer}>
        <View style={styles.loginContainer}>
          <Image
            source={Icons.UrbanPlayground}
            contentFit="contain"
            style={styles.icon}
            responsivePolicy="initial"
          />

          <View style={styles.loginView}>
            <View style={styles.titleView}>
              <Text style={styles.titleText}>
                User Management
              </Text>
            </View>

            <View style={styles.loginContent}>
              <TextInput
                style={[styles.input]}
                placeholder="Username"
                placeholderTextColor="white"
                onChangeText={onNameChanged}
                autoCompleteType="off"
                onKeyPress={onInputEnterPressed}
              />

              <TextInput
                style={[styles.input, styles.inputPadding]}
                placeholder="Password"
                placeholderTextColor="white"
                secureTextEntry
                autoCompleteType="off"
                onChangeText={onPasswordChanged}
                onKeyPress={onInputEnterPressed}
              />
            </View>

            <View style={styles.loginButtonView}>
              <View style={styles.loginButtonContainer}>
                {!loading ? (
                  <TouchableOpacity
                    onPress={onLoginPressed}
                    style={styles.button}
                    disabled={!valid}
                  >
                    <LinearGradient
                      colors={valid ? Colors.LOGIN().active : Colors.LOGIN().inactive}
                      style={styles.buttonContent}
                    >
                      <View style={styles.buttonTextView}>
                        <Text style={styles.buttonText}>
                          Sign In
                        </Text>
                      </View>
                    </LinearGradient>
                  </TouchableOpacity>
                ) : (
                  <ActivityIndicator
                    color={Colors.APP.darkBlue}
                    size={RFValue(13)}
                  />
                )}
              </View>

              <View style={styles.loginTextContainer}>
                <Text style={styles.loginText}>
                  {loginMessage}
                </Text>
              </View>
            </View>

            <View style={styles.forgotPasswordContainer}>
              <TouchableOpacity
                onPress={onForgotPasswordPressed}
                style={styles.forgottenPasswordButton}
              >
                <Text style={styles.forgottenPasswordText}>
                  Forgot password?
                </Text>
              </TouchableOpacity>
            </View>
          </View>
        </View>
      </View>

      {passwordModalVisible && (
        <SimpleNotificationModal />
      )}
    </View>
  ));
}
