import React, { useEffect, useRef } from 'react';
import {
  Animated,
  ImageSourcePropType,
  StyleSheet,
  View,
} from 'react-native';
import { useObserver } from 'mobx-react-lite';
import ScreenTypes from '../../Domain/Types/ScreenTypes';
import useIsMounted from '../../Domain/Hooks/useIsMounted';
import useNavigation from '../../Domain/Hooks/useNavigation';
import { sessionTokenRepository } from '../../Domain/Repositories/SessionTokenRepository';
import LoginRedirect from '../LoginRedirect/LoginRedirect';
import { responsiveScreenRepository } from '../../Domain/Repositories/ResponsiveScreenRepository';
import Header from '../Header/Header';
import { Colors } from '../../Config/Colors';
import { modalController } from './Modals/ModalController';

/**
 * The Parent View Controller Props.
 */
interface ParentViewControllerProps
{
  /**
  * The screen type.
  */
  screenType: ScreenTypes;

  /**
  * Children components.
  */
  children?: JSX.Element[] | JSX.Element;

  /**
  * Ignores the login redirect.
  */
  ignoreLoginRedirect?: boolean;

  /**
   * The modals to register.
   */
  registerModals?: JSX.Element[];

  /**
 * The left button icon.
 */
  leftButtonIcon?: ImageSourcePropType;

  /**
   * Callback when the left button was pressed.
   * Defaults to goBack.
   */
  leftButtonAction?: () => void;
}

/**
 * Component that handles the generic/shared activities.
 * @returns JSX.Element.
 */
export default function ParentViewController({
  children,
  screenType,
  ignoreLoginRedirect = false,
  registerModals = [],
  leftButtonIcon,
  leftButtonAction,
}: ParentViewControllerProps): JSX.Element
{
  const opacityAnimatedValue = useRef(new Animated.Value(0)).current;
  const { navigateToScreen, goBack } = useNavigation();
  const isMounted = useIsMounted();

  useEffect(() =>
  {
    (async (): Promise<void> =>
    {
      const token = await sessionTokenRepository.getJwtToken();

      if (token === '' && screenType !== ScreenTypes.Login)
      {
        navigateToScreen(ScreenTypes.Login);
      }
    })();
  }, []);

  // useEffect for handling container fade animation
  useEffect(() =>
  {
    if (isMounted())
    {
      Animated.timing(
        opacityAnimatedValue,
        {
          toValue: 1,
          duration: 0,
          useNativeDriver: false,
        },
      ).start();
    }
  }, [opacityAnimatedValue, isMounted]);

  const onLeftButtonPressed = (): void =>
  {
    if (leftButtonAction === undefined)
    {
      goBack();
      return;
    }

    leftButtonAction();
  };

  return useObserver(() => (
    <Animated.View
      style={[styles.container, {
        opacity: opacityAnimatedValue,
        width: responsiveScreenRepository.newWidth,
        height: responsiveScreenRepository.newHeight,
      }]}
    >
      {!ignoreLoginRedirect && <LoginRedirect />}

      <View style={styles.contentContainer}>
        <View style={styles.headerContainer}>
          <Header
            screenType={screenType}
            leftButtonAction={onLeftButtonPressed}
            leftButtonIcon={leftButtonIcon}
          />
        </View>

        <View style={styles.childrenContainer}>
          {children}
        </View>
      </View>

      {modalController.registerModalComponents(registerModals)}
    </Animated.View>
  ));
}

const styles = StyleSheet.create({
  container: {
    width: '100%',
    height: '100%',
    flexDirection: 'row',
    backgroundColor: Colors.APP.darkBlue,
  },
  contentContainer: {
    flex: 1,
  },
  headerContainer: {
    flex: 0.1,
    width: '100%',
    backgroundColor: 'rgba(0, 0, 0, 0.35)',
  },
  childrenContainer: {
    flex: 0.9,
    width: '100%',
  },
});
