import React, { useEffect, useRef, useState } from 'react';
import {
  LayoutChangeEvent,
  LayoutRectangle,
  StyleSheet,
  View,
} from 'react-native';
import { useObserver } from 'mobx-react-lite';
import BigList from 'react-native-big-list';
import ParentViewController from '../Shared/ParentViewController';
import ScreenTypes from '../../Domain/Types/ScreenTypes';
import UserListItem from './components/UserListItem';
import { userManagementRepository } from '../../Domain/Repositories/UserManagementRepository';
import SsoService from '../../Domain/Services/SsoService';
import IConfirmationDialogModel from '../../Domain/Models/IConfirmationDialogModel';
import { BaseModalType, modalController } from '../Shared/Modals/ModalController';
import INotificationModalData from '../../Domain/Models/INotificationModalData';
import EditRoleModal from '../Shared/Modals/EditRoleModal/EditRoleModal';
import EditRoleModalData from '../../Domain/Models/EditRoleModalData';
import ModalType from '../../Domain/Types/ModalType';
import EditUserModal from '../Shared/Modals/EditUserModal/EditUserModal';
import AddUserModal from '../Shared/Modals/AddUserModal/AddUserModal';

const ItemsPerRow = 8;
/**
 * Renders the User Management screen.
 */
export default function UserManagementScreen(): JSX.Element
{
  const bigListRef = useRef<BigList>(null);
  const [layoutRectangle, setLayoutRectangle] = useState<LayoutRectangle>({
    height: 0,
    width: 0,
    x: 0,
    y: 0,
  });

  const styles = StyleSheet.create({
    container: {
      width: '100%',
      height: '100%',
      justifyContent: 'center',
      alignItems: 'center',
    },
    contentContainer: {
      width: '100%',
      height: '100%',
      justifyContent: 'center',
      alignItems: 'center',
    },
    listContainer: {
      height: '100%',
      width: '100%',
      justifyContent: 'flex-start',
      alignItems: 'flex-start',
    },
    bigList: {
      width: '100%',
      height: '100%',
    },
  });

  useEffect(() =>
  {
    (async (): Promise<void> =>
    {
      await SsoService.getAllUsers();
    })();
  }, []);

  const onLayout = (event: LayoutChangeEvent): void =>
  {
    setLayoutRectangle(event.nativeEvent.layout);
  };

  const calculateItemHeight = (): number => layoutRectangle.height / ItemsPerRow;

  const getData = (): string[] =>
  {
    if (userManagementRepository.filteredUser.trim() === '')
    {
      return (
        userManagementRepository
          .all
          .slice()
          .map((x) => x.id)
      );
    }

    return (
      userManagementRepository
        .all
        .slice()
        .filter((x) => x.userName
          .toLowerCase()
          .includes(userManagementRepository.filteredUser.toLowerCase()))
        .map((x) => x.id)
    );
  };

  const onAddUserPressed = (): void =>
  {
    modalController.show({
      modalType: ModalType.AddUserModal,
    });
  };

  const onEditUserPressed = (id: string): void =>
  {
    const modalData: EditRoleModalData = {
      id,
    };

    modalController.show({
      modalType: ModalType.EditUserModal,
      data: modalData,
    });
  };

  const onEditRolePressed = (id: string): void =>
  {
    const modalData: EditRoleModalData = {
      id,
    };

    modalController.show({
      modalType: ModalType.EditRoleModal,
      data: modalData,
    });
  };

  const onResetPasswordPressed = (id: string): void =>
  {
    const data: IConfirmationDialogModel = {
      message: 'Are you sure you want to reset the password for this user?',
      title: 'Info',
      positiveAction: async () =>
      {
        const response = await SsoService.resetPassword(id);

        if (response.success)
        {
          const passwordResponseData = response.data;
          if (passwordResponseData !== undefined)
          {
            showNotification(`Successfully resetted the password!\nThe new resetted password is ${passwordResponseData.password}.`);
            return;
          }

          showNotification('Successfully resetted the password but no data received back from the server!');
          return;
        }

        showNotification(response.reason, true);
      },
      negativeAction: (): void => modalController.hide(),
    };

    modalController.show({
      modalType: BaseModalType.GenericConfirmation,
      data,
    });
  };

  const showNotification = (message = '', error = false): void =>
  {
    const data: INotificationModalData = {
      title: error ? 'Error' : 'Info',
      message,
    };

    modalController.show({
      modalType: BaseModalType.SimpleNotification,
      data,
    });
  };

  return useObserver(() => (
    <ParentViewController
      screenType={ScreenTypes.UserManagement}
      registerModals={[
        <EditRoleModal key="assignscreenmodal" />,
        <EditUserModal key="editusermodal" />,
        <AddUserModal key="addusermodal" />,
      ]}
    >
      <View style={styles.container}>
        <View style={styles.contentContainer}>
          <View
            style={styles.listContainer}
            onLayout={onLayout}
          >
            {layoutRectangle.width > 0 && layoutRectangle.height > 0 && (
              <BigList<string>
                ref={bigListRef}
                stickyHeaderIndices={[0]}
                itemHeight={calculateItemHeight()}
                headerHeight={calculateItemHeight()}
                style={styles.bigList}
                data={getData()}
                onContentSizeChange={(): void =>
                {
                  if (bigListRef !== null && bigListRef.current !== null)
                  {
                    bigListRef.current.scrollToEnd({ animated: true });
                  }
                }}
                renderHeader={(): JSX.Element => (
                  <UserListItem
                    isHeader
                    itemWidth={layoutRectangle.width}
                    itemHeight={calculateItemHeight()}
                    addUserPressed={onAddUserPressed}
                  />
                )}
                renderItem={({ item, index }): JSX.Element => (
                  <UserListItem
                    id={item}
                    index={index}
                    itemWidth={layoutRectangle.width}
                    itemHeight={calculateItemHeight()}
                    editRolePressed={onEditRolePressed}
                    resetPasswordPressed={onResetPasswordPressed}
                    editUserPressed={onEditUserPressed}
                  />
                )}
                keyExtractor={(item): string => JSON.stringify(item)}
                showsHorizontalScrollIndicator={false}
                showsVerticalScrollIndicator={false}
              />
            )}
          </View>
        </View>
      </View>
    </ParentViewController>
  ));
}
