import React from 'react';
import {
  ImageSourcePropType,
  StyleSheet,
  Text,
  TextInput,
  TouchableOpacity,
  View,
} from 'react-native';
import { RFValue } from 'react-native-responsive-fontsize';
import { useObserver } from 'mobx-react-lite';
import { Image } from 'expo-image';
import Fonts from '../../Domain/Types/Fonts';
import ScreenTypes from '../../Domain/Types/ScreenTypes';
import Icons from '../../Assets/Icons';
import { Colors } from '../../Config/Colors';
import { userManagementRepository } from '../../Domain/Repositories/UserManagementRepository';

interface HeaderProps
{
  /**
   * The screen type.
   */
  screenType?: ScreenTypes;

  /**
   * The left button icon.
   */
  leftButtonIcon?: ImageSourcePropType;

  /**
   * Callback when the left button was pressed.
   */
  leftButtonAction?: () => void;
}

/**
 * Renders the header.
 */
export default function Header({
  screenType = ScreenTypes.Login,
  leftButtonIcon = Icons.LogOut,
  leftButtonAction,
}: HeaderProps): JSX.Element
{
  const styles = StyleSheet.create({
    container: {
      width: '100%',
      height: '100%',
      overflow: 'hidden',
    },
    contentContainer: {
      width: '100%',
      height: '100%',
      justifyContent: 'center',
      alignItems: 'center',
      flexDirection: 'row',
    },
    leftButtonContainer: {
      flex: 0.1,
      height: '100%',
      justifyContent: 'center',
      alignItems: 'center',
    },
    leftButton: {
      width: '100%',
      height: '100%',
      justifyContent: 'center',
      alignItems: 'center',
    },
    leftButtonIcon: {
      width: RFValue(11),
      height: undefined,
      aspectRatio: 1,
      position: 'absolute',
      left: '32%',
    },
    titleContainer: {
      flex: 0.4,
      height: '100%',
      justifyContent: 'center',
      alignItems: 'center',
    },
    title: {
      fontSize: RFValue(12),
      fontFamily: Fonts.JudgeMedium,
      color: 'white',
      width: '100%',
      textAlign: 'left',
      fontWeight: 'bold',
      letterSpacing: RFValue(0.8),
    },
    filterContainer: {
      flex: 0.5,
      height: '100%',
      justifyContent: 'center',
      alignItems: 'flex-start',
    },
    filterContentContainer: {
      width: '85%',
      height: '65%',
      alignItems: 'center',
      justifyContent: 'flex-end',
      flexDirection: 'row',
    },
    filterInput: {
      height: '100%',
      width: '60%',
      backgroundColor: Colors.BLACK(0.25),
      color: 'white',
      padding: '5%',
      fontSize: RFValue(12),
      fontFamily: Fonts.JudgeMedium,
      borderLeftWidth: RFValue(1),
      borderLeftColor: Colors.APP.teal,
    },
    clearFilterButton: {
      height: '100%',
      aspectRatio: 1,
      width: undefined,
      backgroundColor: Colors.APP.teal,
      justifyContent: 'center',
      alignItems: 'center',
    },
    clearFilterIcon: {
      height: '70%',
      aspectRatio: 1,
      width: undefined,
    },
  });

  const getHeaderTitle = (): string =>
  {
    switch (screenType)
    {
      case ScreenTypes.UserManagement:
        return 'User Management';
      default:
        return '';
    }
  };

  const updateFilteredUser = (value: string): void =>
  {
    userManagementRepository.filteredUser = value;
  };

  return useObserver(() => (
    <View style={styles.container}>
      <View style={styles.contentContainer}>
        <View style={styles.leftButtonContainer}>
          <TouchableOpacity
            style={styles.leftButton}
            onPress={leftButtonAction}
          >
            <Image
              source={leftButtonIcon}
              style={styles.leftButtonIcon}
              contentFit="contain"
              responsivePolicy="initial"
            />
          </TouchableOpacity>
        </View>

        <View style={styles.titleContainer}>
          <Text style={styles.title}>
            {getHeaderTitle()}
          </Text>
        </View>

        <View style={styles.filterContainer}>
          <View style={styles.filterContentContainer}>
            <TextInput
              style={styles.filterInput}
              placeholder="Search user"
              placeholderTextColor="white"
              autoCompleteType="off"
              value={userManagementRepository.filteredUser}
              onChangeText={updateFilteredUser}
            />

            <TouchableOpacity
              style={[styles.clearFilterButton, {
                backgroundColor: userManagementRepository.filteredUser.trim() !== '' ? Colors.APP.red : Colors.APP.teal,
                opacity: userManagementRepository.filteredUser.trim() !== '' ? 1 : 0.5,
              }]}
              disabled={userManagementRepository.filteredUser.trim() === ''}
              onPress={(): void => updateFilteredUser('')}
            >
              <Image
                responsivePolicy="initial"
                style={styles.clearFilterIcon}
                source={Icons.Exit}
              />
            </TouchableOpacity>
          </View>
        </View>
      </View>
    </View>
  ));
}
