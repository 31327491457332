import React from 'react';
import {
  StyleSheet, Text, TouchableOpacity, View,
} from 'react-native';
import { RFValue } from 'react-native-responsive-fontsize';
import { useObserver } from 'mobx-react-lite';
import { Image } from 'expo-image';
import { Colors } from '../../../Config/Colors';
import Fonts from '../../../Domain/Types/Fonts';
import Icons from '../../../Assets/Icons';
import { userManagementRepository } from '../../../Domain/Repositories/UserManagementRepository';
import Roles from '../../../Domain/Types/Roles';

interface UserListItemProps
{
  /**
   * The user id.
   */
  id?: string;

  /**
   * The item width.
   */
  itemWidth?: number;

  /**
   * The item height.
   */
  itemHeight?: number;

  /**
   * Item index.
   */
  index?: number;

  /**
   * Is header?
   */
  isHeader?: boolean;

  /**
   * The username header title.
   */
  usernameHeaderTitle?: string;

  /**
   * The role header title.
   */
  roleHeaderTitle?: string;

  /**
   * Callback when the add user was pressed.
   */
  addUserPressed?: () => void;

  /**
   * Callback when the edit role was pressed.
   */
  editRolePressed?: (id: string) => void;

  /**
   * Callback when the reset password was pressed.
   */
  resetPasswordPressed?: (id: string) => void;

  /**
   * Callback when the delete user option was pressed.
   */
  editUserPressed?: (id: string) => void;
}

/**
 * Renders the User List Item.
 */
export default function UserListItem({
  id,
  itemWidth = RFValue(50),
  itemHeight = RFValue(50),
  index = 0,
  isHeader = false,
  usernameHeaderTitle = 'Username',
  roleHeaderTitle = 'Role',
  editRolePressed,
  addUserPressed,
  resetPasswordPressed,
  editUserPressed,
}: UserListItemProps): JSX.Element
{
  const onEditRolePressed = (): void =>
  {
    if (editRolePressed !== undefined && id !== undefined)
    {
      editRolePressed(id);
    }
  };

  const onAddUserPressed = (): void =>
  {
    if (addUserPressed !== undefined)
    {
      addUserPressed();
    }
  };

  const onResetPasswordPressed = (): void =>
  {
    if (resetPasswordPressed !== undefined && id !== undefined)
    {
      resetPasswordPressed(id);
    }
  };

  const onEditUserPressed = (): void =>
  {
    if (editUserPressed !== undefined && id !== undefined)
    {
      editUserPressed(id);
    }
  };

  const getBackgroundColor = (): string | undefined =>
  {
    if (isHeader)
    {
      return Colors.BLACK(0.2);
    }

    return index % 2 !== 0 ? Colors.BLACK(0.2) : undefined;
  };

  const styles = StyleSheet.create({
    container: {
      width: itemWidth,
      height: itemHeight,
      flexDirection: 'row',
      backgroundColor: Colors.APP.darkBlue,
    },
    bottomBorder: {
      borderBottomWidth: RFValue(2),
      borderBottomColor: Colors.APP.teal,
    },
    contentContainer: {
      width: '100%',
      height: '100%',
      flexDirection: 'row',
      backgroundColor: getBackgroundColor(),
    },
    positionContainer: {
      flex: 0.5,
      height: '100%',
      justifyContent: 'center',
      alignItems: 'center',
      backgroundColor: Colors.APP_OPACITY(0.4).darkerBlue,
    },
    position: {
      fontSize: RFValue(12),
      fontFamily: Fonts.JudgeMedium,
      color: 'white',
    },
    usernameContainer: {
      flex: 3,
      height: '100%',
      justifyContent: 'center',
      alignItems: 'flex-start',
    },
    username: {
      fontSize: RFValue(12),
      fontFamily: Fonts.JudgeMedium,
      color: 'white',
      paddingLeft: '10%',
      letterSpacing: RFValue(0.8),
    },
    rolesContainer: {
      flex: 2,
      height: '100%',
      justifyContent: 'center',
      alignItems: 'center',
      backgroundColor: Colors.APP_OPACITY(0.4).darkerBlue,
    },
    roleContentContainer: {
      width: '100%',
      height: '100%',
      flexDirection: 'row',
    },
    roleHeaderTitle: {
      fontSize: RFValue(12),
      fontFamily: Fonts.JudgeMedium,
      color: 'white',
      paddingLeft: '3%',
      letterSpacing: RFValue(0.8),
    },
    roleTitleContainer: {
      flex: 3,
      height: '100%',
      justifyContent: 'center',
      alignItems: 'flex-start',
    },
    roleTitle: {
      fontSize: RFValue(12),
      fontFamily: Fonts.JudgeMedium,
      color: 'white',
      paddingLeft: '10%',
      letterSpacing: RFValue(0.8),
    },
    editRoleContainer: {
      flex: 1,
      height: '100%',
      justifyContent: 'center',
      alignItems: 'center',
    },
    editRoleButton: {
      width: '100%',
      height: '60%',
      borderLeftWidth: RFValue(2),
      borderLeftColor: Colors.APP.teal,
      justifyContent: 'center',
      alignItems: 'center',
    },
    editRoleIcon: {
      height: '48%',
      aspectRatio: 1,
      width: undefined,
      marginRight: '15%',
    },
    optionsContainer: {
      flex: 3,
      height: '100%',
      justifyContent: 'center',
      alignItems: 'center',
    },
    optionsContentContainer: {
      width: '100%',
      height: '100%',
      justifyContent: 'flex-end',
      alignItems: 'center',
      flexDirection: 'row',
    },
    editUserButton: {
      width: '40%',
      height: '60%',
      borderLeftWidth: RFValue(2),
      borderLeftColor: Colors.APP.teal,
      justifyContent: 'center',
      marginRight: '5%',
    },
    editUserTitle: {
      fontSize: RFValue(12),
      fontFamily: Fonts.JudgeMedium,
      color: 'white',
      paddingLeft: '10%',
      letterSpacing: RFValue(0.8),
    },
  });

  const renderOptions = (): JSX.Element =>
  {
    if (isHeader)
    {
      return (
        <View style={styles.optionsContentContainer}>
          <TouchableOpacity
            style={styles.editUserButton}
            onPress={onAddUserPressed}
          >
            <Text style={styles.editUserTitle}>
              Add User
            </Text>
          </TouchableOpacity>
        </View>
      );
    }

    return (
      <View style={styles.optionsContentContainer}>
        <TouchableOpacity
          style={styles.editUserButton}
          onPress={onResetPasswordPressed}
        >
          <Text style={styles.editUserTitle}>
            Reset Password
          </Text>
        </TouchableOpacity>

        <TouchableOpacity
          style={styles.editUserButton}
          onPress={onEditUserPressed}
        >
          <Text style={styles.editUserTitle}>
            Edit User
          </Text>
        </TouchableOpacity>
      </View>
    );
  };

  const getUsername = (): string =>
  {
    if (id === undefined)
    {
      return 'Unknown User';
    }

    const user = userManagementRepository.getById(id);
    if (user === undefined)
    {
      return 'Unknown User';
    }

    return user.userName;
  };

  const getRole = (): string =>
  {
    if (id === undefined)
    {
      return 'Unknown';
    }

    const user = userManagementRepository.getById(id);
    if (user === undefined)
    {
      return 'Unknown';
    }

    const role = user.roles;
    if (!Array.isArray(role))
    {
      return role;
    }

    if (role.includes(Roles.Admin))
    {
      return Roles.Admin;
    }

    if (role.includes(Roles.Manager))
    {
      return Roles.Manager;
    }

    if (role.includes(Roles.Supervisor))
    {
      return Roles.Supervisor;
    }

    return Roles.Operator;
  };

  const renderRoleContent = (): JSX.Element =>
  {
    if (isHeader)
    {
      return (
        <Text style={styles.roleHeaderTitle}>
          {roleHeaderTitle}
        </Text>
      );
    }

    return (
      <View style={styles.roleContentContainer}>
        <View style={styles.roleTitleContainer}>
          <Text style={styles.roleTitle}>
            {getRole()}
          </Text>
        </View>

        <View style={styles.editRoleContainer}>
          <TouchableOpacity
            style={styles.editRoleButton}
            onPress={onEditRolePressed}
          >
            <Image
              source={Icons.Edit}
              style={styles.editRoleIcon}
              contentFit="contain"
              responsivePolicy="initial"
            />
          </TouchableOpacity>
        </View>
      </View>
    );
  };

  return useObserver(() => (
    <View style={[styles.container, isHeader ? styles.bottomBorder : undefined]}>
      <View style={styles.contentContainer}>
        <View style={styles.positionContainer}>
          <Text style={styles.position}>
            {isHeader ? '#' : (index + 1).toString().padStart(2, '0')}
          </Text>
        </View>

        <View style={styles.usernameContainer}>
          <Text style={styles.username}>
            {isHeader ? usernameHeaderTitle : getUsername()}
          </Text>
        </View>

        <View style={styles.rolesContainer}>
          {renderRoleContent()}
        </View>

        <View style={styles.optionsContainer}>
          {renderOptions()}
        </View>
      </View>
    </View>
  ));
}
