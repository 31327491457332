import { useCallback, useEffect, useRef } from 'react';

/**
 * Use Is Mounted hook.
 */
export default function useIsMounted(): () => boolean
{
  const isMounted = useRef(false);

  useEffect(() =>
  {
    isMounted.current = true;

    return (): void =>
    {
      isMounted.current = false;
    };
  }, []);

  return useCallback(() => isMounted.current, []);
}
